<template>
  <div class="container-fluid">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Permission Management</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" id="name" name="name" class="form-control" placeholder="Name"
                      v-model="permission.name" :class="{
                        'p-invalid':
                          v$.permission.name.$error || errorFor('name'),
                      }" autocomplete="off" />
                    <v-errors :serverErrors="errorFor('name')" :vuelidateErrors="{
                      errors: v$.permission.name.$errors,
                      value: 'Name',
                    }"></v-errors>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-md-6">
                  <h4 class="header-title mt-5 mt-sm-0">
                    Assign Role To Permission
                  </h4>
                  <div class="mt-2">
                    <div class="form-check" v-for="(role, index) in roles" :key="`role${index}`">
                      <input type="checkbox" class="form-check-input" :id="`role-${index}`" :value="role"
                        v-model="permission.roles" />
                      <label class="form-check-label" :for="`role-${index}`">{{
                        role
                      }}</label>
                    </div>
                    <v-errors :serverErrors="errorFor('roles')" :vuelidateErrors="{
                      errors: v$.permission.roles.$errors,
                      value: 'Role',
                    }"></v-errors>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'permissions' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button :disabled="isLoading ? true : false" type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3" @click="
                        currentRouteName === 'permission-update'
                          ? updatePermission()
                          : createPermission()
                        ">
                      <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                      {{ isLoading == true ? 'Loading...' : 'Save' }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  mixins: [validationErrors],
  data() {
    return {
      permission: {
        name: "",
        roles: [],
      },
      roles: [],
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  validations() {
    return {
      permission: {
        name: { required },
        roles: { required },
      },
    };
  },
  methods: {
    getPermissionById() {
      axios
        .get(`${this.baseUrl}admin/v1/permissions/${this.$route.params.id}`)
        .then((response) => {
          this.roles = response.data.data.roles;
          this.permission.name = response.data.data.permission.name;
          this.permission.roles = response.data.data.permission.roles.map(
            (role) => role.name
          );
        })
        .catch(() => {
          this.$router.push({ name: "permissions" });
          this.toast.error("Not Found Permission!");
        });
    },
    getRoles() {
      axios
        .get(`${this.baseUrl}admin/v1/roles`)
        .then((response) => {
          this.roles = response.data.data.map((role) => role.name);
        })
        .catch(() => {
          this.$router.push({ name: "roles" });
          this.toast.error("Not Found Role!");
        });
    },
    async createPermission() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      axios
        .post(`${this.baseUrl}admin/v1/permissions`, this.permission)
        .then(() => {
          this.$router.push({ name: "permissions" });
          this.toast.success("Successfully Permission Created!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
    async updatePermission() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;

      axios
        .put(
          `${this.baseUrl}admin/v1/permissions/${this.$route.params.id}`,
          this.permission
        )
        .then(() => {
          this.$router.push({ name: "permissions" });
          this.toast.success("Successfully Permission Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },
  },
  created() {
    if (this.currentRouteName === "permission-update") {
      this.getPermissionById();
    } else {
      this.getRoles();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>